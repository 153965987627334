<template>
  <v-data-table
    :headers="headers"
    :items="financeProjets"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Finance Projet
        </v-toolbar-title>
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        <template v-slot:activator="{ on, attrs}">

            <download-excel
              class="mr-3 mb-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
              :data="financeProjets"
              :fields="json_fields"
              type="csv"
              v-bind="attrs"
              v-on="on"
            >
              Download CSV
            </download-excel>
            <download-excel
              class="mr-3 mb-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
              :data="financeProjets"
              :fields="json_fields"
              type="xls"
              v-bind="attrs"
              v-on="on"
            >
              Download Excel
            </download-excel>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.libelle_organisation"
                      label="Organisation"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.directeur"
                      label="Nom et Prénom(directeur)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.email"
                      label="Email(directeur)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.telephone"
                      label="Téléphone(directeur)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.adresses"
                      label="Adresse"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.longitude_organisations"
                      label="Longitude"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="financeProjetEdit.latitude_organisations"
                      label="Latitude"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-file-input
                      v-model="financeProjetEdit.logo_organisations"
                      chips
                      small-chips
                      truncate-length="15"
                      label="Logo ONG"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darke-1"
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darke-1"
                @click="saveFinanceProjet"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.logo_organisations="{item}">
      <v-img
        :lazy-src="item.logo_organisations"
        max-height="150"
        max-width="250"
        :src="item.logo_organisations"
      />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import profilOng from '@/services/profile-ong.js'
  import financeProjet from '@/services/finance-projet.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        json_fields: {
          'Structure Financière': 'structure_financiere',
          'Locationsation structure': 'localisation_structure',
          'Personne contact(Nom & Prénom)': 'personne_contact',
          'Personne contact(Téléphone)': 'telephone_personne_contact',
          'Personne contact(Email)': 'email_personne_contact',
          'Montant déposé': 'montant_depose',
          'Date de signature contrat': 'date_signature_contrat',
          "Taux d'interet": 'taux_interet',
          'Date de début crédit': 'date_debut_credit',
        },
        headers: [
          {
            text: 'Structure Financière',
            value: 'structure_financiere',
          },
          {
            text: 'Locationsation structure',
            value: 'localisation_structure',
          },
          {
            text: 'Personne contact(Nom & Prénom)',
            value: 'personne_contact',
          },
          {
            text: 'Personne contact(Téléphone)',
            value: 'telephone_personne_contact',
          },
          {
            text: 'Personne contact(Email)',
            value: 'email_personne_contact',
          },
          {
            text: 'Montant déposé',
            value: 'montant_depose',
          },
          {
            text: 'Latitude Organisation',
            value: 'latitude_organisations',
          },
          {
            text: 'Contrat',
            value: 'contrat',
          },
          {
            text: 'Date de signature contrat',
            value: 'date_signature_contrat',
          },
          {
            text: "Taux d'interet",
            value: 'taux_interet',
          },
          {
            text: 'Date de début crédit',
            value: 'date_debut_credit',
          },
        ],
        financeProjets: [],
        editedIndex: -1,
        financeProjetEdit: {
          id: '',
          libelle_organisation: '',
          adresses: '',
          directeur: '',
          telephone: '',
          email: '',
          longitude_organisations: '',
          latitude_organisations: '',
          logo_organisations: '',
        },
        financeProjet: {
          libelle_organisation: '',
          adresses: '',
          directeur: '',
          telephone: '',
          email: '',
          longitude_organisations: '',
          latitude_organisations: '',
          logo_organisations: '',
        },
      }
    },
    mounted () {
      console.log(this.editedIndex)
      financeProjet.getFinanceProjets().then(response => {
        this.financeProjets = response.data
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un profil' : 'Modifier un profil'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      saveFinanceProjet () {
        if (this.editedIndex > -1) {
          profilOng.updateUser(this.financeProjetEdit).then(response => {
            console.log(response.data)
            profilOng.getUsersProfile().then(response => {
              this.profiles = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        } else {
          profilOng.createUser(this.financeProjetEdit).then(response => {
            console.log(response.data)
            profilOng.getUsersProfile().then(response => {
              this.profiles = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.financeProjetEdit = Object.assign({}, this.financeProjet)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.profiles.indexOf(item)
        this.financeProjetEdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.profiles.indexOf(item)
        this.financeProjetEdit = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        profilOng.deleteUser(this.financeProjetEdit).then(response => {
          profilOng.getUsersProfile().then(response => {
            this.profiles = response.data
          })
        }).catch(err => {
          console.log(err.data)
        })
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.financeProjetEdit = Object.assign({}, this.financeProjet)
          this.editedIndex = -1
        })
      },
    },
  }
</script>

<style scoped>

</style>
