import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getUsersProfile () {
    return apiClient.get('api/profil/list-ong')
  },
  createUser (profileInformations) {
    const formData = new FormData()
    formData.append('libelle_organisation', profileInformations.libelle_organisation)
    formData.append('adresses', profileInformations.adresses)
    formData.append('directeur', profileInformations.directeur)
    formData.append('telephone', profileInformations.telephone)
    formData.append('email', profileInformations.email)
    formData.append('longitude_organisations', profileInformations.longitude_organisations)
    formData.append('latitude_organisations', profileInformations.latitude_organisations)
    formData.append('logo_organisations', profileInformations.logo_organisations)
    return apiClient.post('api/profil/add-ong', formData)
  },

  updateUser (profileInformations) {
    const formData = new FormData()
    formData.append('libelle_organisation', profileInformations.libelle_organisation)
    formData.append('adresses', profileInformations.adresses)
    formData.append('directeur', profileInformations.directeur)
    formData.append('telephone', profileInformations.telephone)
    formData.append('email', profileInformations.email)
    formData.append('longitude_organisations', profileInformations.longitude_organisations)
    formData.append('latitude_organisations', profileInformations.latitude_organisations)
    formData.append('logo_organisations', profileInformations.logo_organisations)
     return apiClient.post(`api/profil/update-ong/${profileInformations.id}`, formData)
  },

  deleteUser (profileInformations) {
    return apiClient.post(`api/profil/delete-ong/${profileInformations.id}`)
 },
}
